import React, { useState } from "react"

import { AnimatePresence, motion } from "framer-motion"
import PropTypes from "prop-types"
import Select from "react-select"
import styled from "styled-components"

import emptyFunction from "src/emptyFunction"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import { DatePicker } from "components/Forms/DatePicker"

const ComponentWrapper = styled.div`
  position: relative;
  height: ${props =>
    props.errors ? "75px" : props.height ? props.height : "50px"};
`

const Container = styled.div`
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  form {
    width: 100%;
    display: flex;
    align-items: center;
  }
  button {
    order: 1;
  }
  h3 {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  input {
    display: inline-block;
    flex-grow: 1;
  }
  .autocomplete-container {
    flex-grow: 1;
  }
  button[type="submit"] {
    margin-left: 10px;
  }
`

const EditableDisplay = props => {
  const {
    displayValue,
    editableValue,
    save,
    errors,
    clearErrors,
    type,
    height,
    selectOptions,
    isMulti,
    tag: Tag,
    ...autocompleteProps
  } = props
  const [editing, setEditing] = useState(false)
  const [saving, setSaving] = useState(false)
  const [value, setValue] = useState(editableValue ?? displayValue)
  const [selectedId, setSelectedId] = useState(null)

  const onChange = e => {
    const value =
      type === "date"
        ? e
        : type === "checkbox"
        ? e.currentTarget.checked
        : e.currentTarget.value
    setValue(value)
  }

  const autocompleteSelected = option => {
    if (!option?.value) return
    setSelectedId(option.value)
    setValue(option.label)
  }

  const cancel = () => {
    setValue(editableValue ?? displayValue)
    setEditing(false)
  }

  const motionProps = {
    initial: "hidden",
    animate: "visible",
    exit: "hidden",
    style: { width: "100%" },
    transition: { duration: 0.5 },
  }

  if (!height) {
    motionProps.style.position = "absolute"
  }

  return (
    <ComponentWrapper errors={errors} height={height}>
      <AnimatePresence initial={false}>
        {editing && (
          <motion.div
            {...motionProps}
            variants={{
              hidden: {
                y: 50,
                opacity: 0,
                transitionEnd: { display: "none" },
              },
              visible: {
                y: 0,
                opacity: 1,
                display: "block",
              },
            }}
          >
            <Container>
              <form
                onSubmit={e => {
                  e.preventDefault()
                  setSaving(true)
                  save({ value, selectedId }).then(() => {
                    setSaving(false)
                    setEditing(false)
                  })
                }}
              >
                {autocompleteProps.autocomplete ? (
                  <div className="autocomplete-container">
                    <AutocompleteSelect
                      {...autocompleteProps}
                      onChange={autocompleteSelected}
                      isMulti={isMulti}
                      autoFocus
                    />
                  </div>
                ) : type === "select" ? (
                  <div className="autocomplete-container">
                    <Select
                      options={selectOptions}
                      onChange={selected => {
                        if (Array.isArray(selected)) {
                          setValue(selected.map(s => s.value))
                        } else {
                          setValue(selected?.value)
                        }
                      }}
                      isMulti={isMulti}
                      styles={{
                        container: provided => ({
                          ...provided,
                          maxWidth: 250,
                        }),
                      }}
                    />
                  </div>
                ) : type === "date" ? (
                  <DatePicker value={value} onChange={onChange} />
                ) : type === "textarea" ? (
                  <textarea
                    className="form-control"
                    onChange={onChange}
                    value={value}
                    autoFocus
                  />
                ) : type === "checkbox" ? (
                  <div className="form-check d-inline-flex mb-3">
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        checked={value}
                        className="form-check-input"
                        onChange={onChange}
                        value={value}
                        autoFocus
                      />
                      <span className="form-check-sign" />
                    </label>
                  </div>
                ) : (
                  <input
                    type={type}
                    className="form-control"
                    onChange={onChange}
                    value={value}
                    autoFocus
                  />
                )}
                <button
                  type="submit"
                  className="btn btn-sm btn-success"
                  disabled={saving}
                >
                  {saving ? (
                    <i className="nc-icon nc-refresh-69 spinner" />
                  ) : (
                    "Save"
                  )}
                </button>
                <button
                  type="reset"
                  onClick={cancel}
                  className="btn btn-sm btn-danger"
                  disabled={saving}
                >
                  Cancel
                </button>
              </form>
            </Container>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence initial={false}>
        {!editing && (
          <motion.div
            {...motionProps}
            variants={{
              hidden: {
                y: -50,
                opacity: 0,
                transitionEnd: { display: "none" },
              },
              visible: {
                y: 0,
                opacity: 1,
                display: "block",
              },
            }}
          >
            <Container>
              <Tag className="d-inline-block">{displayValue}</Tag>
              <button
                className="btn btn-sm btn-link btn-info"
                onClick={() => {
                  clearErrors()
                  setEditing(true)
                }}
              >
                <i className="fa fa-pencil" />
              </button>
            </Container>
            {errors && <div className="text-danger">{errors}</div>}
          </motion.div>
        )}
      </AnimatePresence>
    </ComponentWrapper>
  )
}

EditableDisplay.propTypes = {
  displayValue: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  editableValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  save: PropTypes.func.isRequired,
  autocomplete: PropTypes.bool,
  placeholder: PropTypes.string,
  api: PropTypes.string,
  searchOptions: PropTypes.string,
  errors: PropTypes.string,
  clearErrors: PropTypes.func,
}

EditableDisplay.defaultProps = {
  tag: "div",
  type: "text",
  clearErrors: emptyFunction,
  errors: null,
}

export default EditableDisplay
