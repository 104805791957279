import React from "react"

import { FieldArray } from "formik"
import PropTypes from "prop-types"

import axios from "src/axios"

import Pagination from "components/Pagination"

class QuestionOptions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      questions: [],
      currentPage: 1,
    }
  }

  componentDidMount = () => {
    this.loadQuestions(this.state.currentPage)
  }

  loadQuestions = page => {
    const { apiNamespace, tags } = this.props
    axios
      .get(`/api/${apiNamespace}/tailored_questions`, {
        params: { page: page, tags: tags },
      })
      .then(response => {
        const { pages, currentPage, data: questions } = response.data
        this.setState({ questions, pages, currentPage })
      })
  }

  render() {
    const { pages, currentPage, questions } = this.state
    const { values } = this.props
    return (
      <FieldArray name="questionIds">
        {arrayHelpers => (
          <React.Fragment>
            {questions.map(({ id, type, text, image }, index) => (
              <div className="form-check" key={id}>
                <label className="form-check-label">
                  {type === "text" ? (
                    text
                  ) : (
                    <img src={image.url} alt="question" />
                  )}
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={id}
                    defaultChecked={values.questionIds.includes(id)}
                    onChange={e =>
                      e.target.checked
                        ? arrayHelpers.push(id)
                        : arrayHelpers.remove(values.questionIds.indexOf(id))
                    }
                  />
                  <span className="form-check-sign" />
                </label>
              </div>
            ))}
            <div className="row">
              <div className="col-4">
                <Pagination
                  totalPages={pages}
                  currentPage={currentPage}
                  changePage={this.loadQuestions}
                />
              </div>
            </div>
          </React.Fragment>
        )}
      </FieldArray>
    )
  }
}

QuestionOptions.propTypes = {
  apiNamespace: PropTypes.string.isRequired,
  values: PropTypes.shape({
    questionIds: PropTypes.array.isRequired,
  }).isRequired,
  tags: PropTypes.array.isRequired,
}

export default QuestionOptions
