import React, { useState } from "react"

import {
  buildMutation,
  buildQuery,
  compress,
  useMutation,
  useQuery,
} from "micro-graphql-react"
import { NotificationContainer } from "react-notifications"

import { css } from "@emotion/core"
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { courseTypeOptions } from "src/enums"
import ordered from "src/ordered"

import { useGradeOptions } from "hooks/remoteTable"

import EditableDisplay from "components/Forms/EditableDisplay"
import EditableDisplayWithReset from "components/Forms/EditableDisplayWithReset"
import LocalTime from "components/LocalTime"
import Modal from "components/Modal"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import NewCourse from "./NewCourse"
import Tutors from "./Tutors"
import UpdatePublishedAt from "./UpdatePublishedAt"

import "react-notifications/lib/notifications.css"

const CourseClusterDetails = ({ id }) => {
  const [errors, setErrors] = useState()
  const gradeOptions = useGradeOptions()
  const loadingState = useQuery(
    buildQuery(
      courseClusterQuery,
      { id },
      {
        onMutation: [
          {
            when: "updateCourseCluster",
            run: ({ softReset, currentResults }, response) => {
              const { failures, courseCluster } = response.updateCourseCluster
              if (failures.length > 0) {
                setErrors(failures.map(failure => failure.message).join(", "))
              } else {
                currentResults.courseCluster.name = courseCluster.name
                currentResults.courseCluster.description =
                  courseCluster.description
                currentResults.courseCluster.formattedDescription =
                  courseCluster.formattedDescription
                currentResults.courseCluster.courseType =
                  courseCluster.courseType
                currentResults.courseCluster.subject = courseCluster.subject
                currentResults.courseCluster.school = courseCluster.school
                currentResults.courseCluster.grades = courseCluster.grades
                currentResults.courseCluster.splitBill = courseCluster.splitBill
                currentResults.courseCluster.immovable = courseCluster.immovable
                currentResults.courseCluster.excusedAbsencesEnabled =
                  courseCluster.excusedAbsencesEnabled
                currentResults.courseCluster.requestStudyGroupConfirmations =
                  courseCluster.requestStudyGroupConfirmations
                currentResults.courseCluster.canExceedScheduledDuration =
                  courseCluster.canExceedScheduledDuration
                currentResults.courseCluster.inSchool = courseCluster.inSchool
                currentResults.courseCluster.allowLongSessions =
                  courseCluster.allowLongSessions
                currentResults.courseCluster.publishedAt =
                  courseCluster.publishedAt
                softReset(currentResults)
              }
            },
          },
          {
            when: "unpublishCourseCluster",
            run: ({ softReset, currentResults }, response) => {
              const { errorMessages, courseCluster } =
                response.unpublishCourseCluster
              if (errorMessages) {
                setErrors(errorMessages)
              } else {
                currentResults.courseCluster.publishedAt =
                  courseCluster.publishedAt
                softReset(currentResults)
              }
            },
          },
          {
            when: "courseClusterAddTutors",
            run: ({ softReset, currentResults }, response) => {
              const { errorMessages, courseCluster } =
                response.courseClusterAddTutors
              if (errorMessages) {
                return
              }

              currentResults.courseCluster.tutors = courseCluster.tutors
              softReset(currentResults)
            },
          },
          {
            when: "courseClusterRemoveTutor",
            run: ({ softReset, currentResults }, response) => {
              const { errorMessages, courseCluster } =
                response.courseClusterRemoveTutor
              if (errorMessages) {
                return
              }

              currentResults.courseCluster.tutors = courseCluster.tutors
              softReset(currentResults)
            },
          },
          {
            when: "courseClusterResetAllowLongSessions",
            run: ({ softReset, currentResults }, response) => {
              const { failures, courseCluster } =
                response.courseClusterResetAllowLongSessions
              if (failures.length > 0) return

              currentResults.courseCluster.allowLongSessions =
                courseCluster.allowLongSessions
              softReset(currentResults)
            },
          },
        ],
      }
    )
  )
  const { runMutation } = useMutation(
    buildMutation(updateCourseClusterMutation)
  )
  const { runMutation: resetAllowLongSessions } = useMutation(
    buildMutation(resetAllowLongSessionsMutation)
  )

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <>
          <NotificationContainer />
          <div className="row">
            <div className="col-12">
              {errors && <div className="alert alert-danger">{errors}</div>}
              <dl>
                <dt>Name</dt>
                <dd>
                  <EditableDisplay
                    displayValue={data.courseCluster.name}
                    save={({ value }) => runMutation({ id, name: value })}
                  />
                </dd>

                <dt>Description</dt>
                <dd>
                  <EditableDisplay
                    displayValue={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: data.courseCluster.formattedDescription,
                        }}
                      />
                    }
                    editableValue={data.courseCluster.description}
                    type="textarea"
                    height="auto"
                    save={({ value }) =>
                      runMutation({ id, description: value })
                    }
                  />
                </dd>

                <dt>Publish Date</dt>
                <dd>
                  <div
                    css={css`
                      display: flex;
                      max-width: 500px;
                      align-items: center;
                      justify-content: space-between;
                    `}
                  >
                    <div>
                      {data.courseCluster.publishedAt ? (
                        <LocalTime timestamp={data.courseCluster.publishedAt} />
                      ) : (
                        "Not published"
                      )}
                    </div>
                    <UpdatePublishedAt
                      courseCluster={data.courseCluster}
                      update={runMutation}
                    />
                  </div>
                </dd>

                <dt>Course Type</dt>
                <dd>
                  <EditableDisplay
                    displayValue={data.courseCluster.courseType}
                    type="select"
                    selectOptions={courseTypeOptions}
                    save={({ value }) => runMutation({ id, courseType: value })}
                  />
                </dd>

                <dt>Organization</dt>
                <dd>
                  {data.courseCluster.organization ? (
                    <a href={data.courseCluster.organization.showPath}>
                      {data.courseCluster.organization.name}
                    </a>
                  ) : (
                    "Public"
                  )}
                </dd>

                {data.courseCluster.organization && (
                  <>
                    <dt>School</dt>
                    <dd>
                      <EditableDisplay
                        displayValue={data.courseCluster.school?.name}
                        autocomplete
                        api="/api/admins/schools/autocomplete_name"
                        searchOptions={`organization_id=${data.courseCluster.organization.id}`}
                        isClearable={false}
                        save={({ value, selectedId }) =>
                          runMutation({ id, schoolId: selectedId })
                        }
                      />
                    </dd>
                  </>
                )}

                <dt>Subject</dt>
                <dd>
                  <EditableDisplay
                    displayValue={data.courseCluster.subject.name}
                    autocomplete
                    api="/api/admins/subjects/autocomplete_name"
                    isClearable={false}
                    save={({ value, selectedId }) =>
                      runMutation({ id, subjectId: selectedId })
                    }
                  />
                </dd>

                <dt>Grades</dt>
                <dd>
                  <EditableDisplay
                    height="auto"
                    displayValue={
                      <ul className="list-unstyled">
                        {ordered(data.courseCluster.grades, "number").map(
                          grade => (
                            <li key={grade.id}>{grade.name}</li>
                          )
                        )}
                      </ul>
                    }
                    type="select"
                    selectOptions={gradeOptions}
                    isClearable={false}
                    isMulti
                    save={props => runMutation({ id, gradeIds: props.value })}
                  />
                </dd>

                <dt>Prepaid</dt>
                <dd>
                  {data.courseCluster.prepaid ? (
                    <FontAwesomeIcon icon={faCheck} className="text-success" />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} className="text-danger" />
                  )}
                </dd>

                <dt>Split Bill</dt>
                <dd>
                  <EditableDisplay
                    displayValue={
                      data.courseCluster.splitBill ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-success"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="text-danger"
                        />
                      )
                    }
                    editableValue={data.courseCluster.splitBill}
                    type="checkbox"
                    save={({ value }) => runMutation({ id, splitBill: value })}
                  />
                </dd>

                <dt>Non-Cancelable / Non-Reschedulable</dt>
                <dd>
                  <EditableDisplay
                    displayValue={
                      data.courseCluster.immovable ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-success"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="text-danger"
                        />
                      )
                    }
                    editableValue={data.courseCluster.immovable}
                    type="checkbox"
                    save={({ value }) => runMutation({ id, immovable: value })}
                  />
                </dd>

                <dt>Excused Absences Enabled</dt>
                <dd>
                  <EditableDisplay
                    displayValue={
                      data.courseCluster.excusedAbsencesEnabled ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-success"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="text-danger"
                        />
                      )
                    }
                    editableValue={data.courseCluster.excusedAbsencesEnabled}
                    type="checkbox"
                    save={({ value }) =>
                      runMutation({ id, excusedAbsencesEnabled: value })
                    }
                  />
                </dd>

                <dt>Request Student Study Group Confirmations?</dt>
                <dd>
                  <EditableDisplay
                    displayValue={
                      data.courseCluster.requestStudyGroupConfirmations ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-success"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="text-danger"
                        />
                      )
                    }
                    editableValue={
                      data.courseCluster.requestStudyGroupConfirmations
                    }
                    type="checkbox"
                    save={({ value }) =>
                      runMutation({ id, requestStudyGroupConfirmations: value })
                    }
                  />
                </dd>

                <dt>Tutors can bill for longer than scheduled duration</dt>
                <dd>
                  <EditableDisplay
                    displayValue={
                      data.courseCluster.canExceedScheduledDuration ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-success"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="text-danger"
                        />
                      )
                    }
                    editableValue={
                      data.courseCluster.canExceedScheduledDuration
                    }
                    type="checkbox"
                    save={({ value }) =>
                      runMutation({ id, canExceedScheduledDuration: value })
                    }
                  />
                </dd>

                <dt>In-School Program</dt>
                <dd>
                  <EditableDisplay
                    displayValue={
                      data.courseCluster.inSchool ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-success"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="text-danger"
                        />
                      )
                    }
                    editableValue={data.courseCluster.inSchool}
                    type="checkbox"
                    save={({ value }) => runMutation({ id, inSchool: value })}
                  />
                </dd>

                <dt>Allow Sessions Longer than 1 Hour?</dt>
                <dd>
                  {data.courseCluster.organization ? (
                    <EditableDisplayWithReset
                      value={data.courseCluster.allowLongSessions}
                      reset={() => resetAllowLongSessions({ id })}
                      update={({ value }) =>
                        runMutation({ id, allowLongSessions: value })
                      }
                    />
                  ) : (
                    <EditableDisplay
                      displayValue={
                        data.courseCluster.allowLongSessions ? (
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="text-success"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="text-danger"
                          />
                        )
                      }
                      editableValue={data.courseCluster.allowLongSessions}
                      type="checkbox"
                      save={({ value }) =>
                        runMutation({ id, allowLongSessions: value })
                      }
                    />
                  )}
                </dd>
              </dl>

              <Tutors courseCluster={data.courseCluster} />

              <h2
                css={css`
                  font-size: 24px;
                `}
              >
                Courses
              </h2>
              <table className="table">
                <thead>
                  <tr>
                    <th>Duration (Weeks)</th>
                    <th>Weekly Sessions</th>
                    <th>Minimum Group Size</th>
                    <th>Maximum Group Size</th>
                    <th>Registered Students</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {ordered(data.courseCluster.courses, "durationWeeks").map(
                    course => (
                      <tr key={course.id}>
                        <td>{course.durationWeeks}</td>
                        <td>{course.sessionsPerWeek}</td>
                        <td>{course.minimumStudyGroupSize}</td>
                        <td>{course.maximumStudyGroupSize}</td>
                        <td>{course.studentCount}</td>
                        <td>
                          <div className="flex justify-between">
                            <a
                              href={course.showPath}
                              className="btn btn-sm btn-outline-info nc-icon nc-alert-circle-i mr-3"
                            >
                              <span className="hidden">View</span>
                            </a>
                            <Modal
                              buttonText=""
                              buttonClassName="btn btn-sm btn-outline-danger nc-icon nc-simple-remove"
                              modalTitle="Remove"
                              content={`<p class='lead'>Are you sure? This will remove ${course.name} and all associated sections.</p>`}
                              confirmURL={course.showPath}
                              confirmText="Yes, remove it!"
                              confirmClassName="btn btn-danger"
                              confirmMethod="delete"
                              cancelText="No, keep it for now"
                              cancelClassName="btn btn-info"
                            />
                          </div>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
              <NewCourse courseCluster={data.courseCluster} />
            </div>
          </div>
        </>
      )}
    </WithLoadingIndicator>
  )
}

const courseClusterQuery = compress`
  query($id: ID!) {
    courseCluster(id: $id) {
      id
      name
      description
      formattedDescription
      courseType
      splitBill
      immovable
      excusedAbsencesEnabled
      requestStudyGroupConfirmations
      canExceedScheduledDuration
      inSchool
      allowLongSessions
      prepaid
      publishedAt
      subject {
        id
        name
      }
      organization {
        id
        name
        showPath
      }
      school {
        id
        name
        showPath
      }
      grades {
        id
        name
        number
      }
      tutors {
        id
        fullName
        lastName
      }
      courses {
        id
        durationWeeks
        sessionsPerWeek
        minimumStudyGroupSize
        maximumStudyGroupSize
        showPath
        studentCount
      }
    }
  }
`

const updateCourseClusterMutation = compress`
  mutation(
    $id: ID!
    $name: String
    $description: String
    $courseType: CourseTypeEnum
    $schoolId: ID
    $subjectId: ID
    $gradeIds: [ID!]
    $splitBill: Boolean
    $immovable: Boolean
    $excusedAbsencesEnabled: Boolean
    $requestStudyGroupConfirmations: Boolean
    $canExceedScheduledDuration: Boolean
    $inSchool: Boolean
    $allowLongSessions: Boolean
    $publishedAt: DateTime
  ) {
    updateCourseCluster(
      id: $id
      name: $name
      description: $description
      courseType: $courseType
      schoolId: $schoolId
      subjectId: $subjectId
      gradeIds: $gradeIds
      splitBill: $splitBill
      immovable: $immovable
      excusedAbsencesEnabled: $excusedAbsencesEnabled
      requestStudyGroupConfirmations: $requestStudyGroupConfirmations
      canExceedScheduledDuration: $canExceedScheduledDuration
      inSchool: $inSchool
      allowLongSessions: $allowLongSessions
      publishedAt: $publishedAt
    ) {
      failures {
        message
      }
      courseCluster {
        name
        formattedDescription
        description
        courseType
        splitBill
        immovable
        excusedAbsencesEnabled
        requestStudyGroupConfirmations
        canExceedScheduledDuration
        inSchool
        allowLongSessions
        publishedAt
        school {
          id
          name
          showPath
        }
        subject {
          id
          name
        }
        grades {
          id
          name
          number
        }
      }
    }
  }
`

const resetAllowLongSessionsMutation = compress`
  mutation($id: ID!) {
   courseClusterResetAllowLongSessions(courseClusterId: $id) {
      failures {
        message
      }
      courseCluster {
        allowLongSessions
      }
    }
  }
`

export default CourseClusterDetails
