import React, { ReactNode } from "react"

import { useField, useFormikContext } from "formik"
import Select, { components } from "react-select"

import { SelectOption } from "~tailwindui/types/SelectOption"

import ErrorMessage from "./ErrorMessage"

const NoAutocompleteInput = ({ ...rest }) => (
  <components.Input {...rest} autoComplete="none" />
)

export type AutocompleteSelectProps = {
  name: string
  label?: string
  isMulti?: boolean
  valueAttribute?: string
  maxWidth?: number
  options: SelectOption[]
  isClearable: boolean
  menuPortalTarget: ReactNode
}

const SelectField = React.forwardRef<
  HTMLSelectElement,
  AutocompleteSelectProps
>(({ valueAttribute = "value", maxWidth = 250, ...props }, ref) => {
  const [field, meta] = useField(props)
  const { setFieldValue } = useFormikContext()

  return (
    <div className="form-group">
      <label className="form-label w-100">
        <span>{props.label}</span>
        <div>
          <Select
            name={field.name}
            menuPortalTarget={document.body}
            components={{ Input: NoAutocompleteInput }}
            onChange={selectedOption => {
              if (props.isMulti) {
                setFieldValue(
                  field.name,
                  selectedOption.map(selected => selected?.[valueAttribute])
                )
              } else {
                setFieldValue(field.name, selectedOption?.[valueAttribute])
              }
            }}
            styles={{
              container: provided => ({
                ...provided,
                maxWidth,
              }),
            }}
            ref={ref}
            {...props}
          />
        </div>
        <ErrorMessage {...meta} touched={true} />
      </label>
    </div>
  )
})

export default SelectField
