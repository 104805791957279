import React from "react"

import { publicClient as client } from "src/urql-client"

import { gql, useQuery } from "hooks/urql"
import useSessionStorage from "hooks/useSessionStorage"

import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import CollegeForm from "./CollegeForm"
import FormStateContext from "./FormStateContext"
import GradeOptionsContext from "./GradeOptionsContext"
import ParentForm from "./ParentForm"

const RegistrationWizard = () => {
  const [result] = useQuery({ query: gradesQuery, client })
  const [store, setStore] = useSessionStorage("registrationWizard", {})

  const userType = store.registrationUserType
  const setUserType = type => {
    setStore({ ...store, registrationUserType: type })
  }

  const reset = () => {
    setUserType()
  }

  return (
    <FormStateContext.Provider value={[store, setStore]}>
      <UrqlLoadingIndicator result={result}>
        {({ data }) => (
          <GradeOptionsContext.Provider
            value={{
              tiers: data.tiers,
              grades: data.tiers.map(t => t.grades).flat(),
            }}
          >
            <div className="mx-auto w-full md:w-3/4">
              <h1 className="text-center">Air Tutors Registration</h1>
              {userType ? null : (
                <>
                  <div className="mx-auto flex h-[200px] max-w-lg flex-col justify-evenly">
                    <div className="flex justify-center">
                      <a
                        href="/users/sign_in?openParentModal=true"
                        className="btn blue w-full outline"
                      >
                        I'm a parent of an existing Air Tutors student
                      </a>
                    </div>

                    <div className="flex justify-center">
                      <button
                        type="button"
                        onClick={() => setUserType("parent")}
                        className="btn blue w-full outline"
                      >
                        I'm a parent of a new K-12 student
                      </button>
                    </div>

                    <div className="flex justify-center">
                      <button
                        type="button"
                        onClick={() => setUserType("college")}
                        className="btn blue w-full outline"
                      >
                        I'm a college and beyond student
                      </button>
                    </div>
                  </div>
                </>
              )}
              {userType === "parent" && <ParentForm reset={reset} />}
              {userType === "college" && <CollegeForm reset={reset} />}

              <div className="mt-5 text-center">
                <div>Already have an account?</div>
                <div>
                  <a href="/users/sign_in?programRegistration=true">
                    Click here to login
                  </a>
                </div>
              </div>
            </div>
          </GradeOptionsContext.Provider>
        )}
      </UrqlLoadingIndicator>
    </FormStateContext.Provider>
  )
}

const gradesQuery = gql`
  query {
    tiers {
      id
      name
      gradeRange
      grades {
        id
        name
        number
      }
    }
  }
`

export default RegistrationWizard
