import React from "react"

import { faFrown, faMeh, faSmile } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const weekdayOptions = [
  { label: "Sunday", value: "Sunday" },
  { label: "Monday", value: "Monday" },
  { label: "Tuesday", value: "Tuesday" },
  { label: "Wednesday", value: "Wednesday" },
  { label: "Thursday", value: "Thursday" },
  { label: "Friday", value: "Friday" },
  { label: "Saturday", value: "Saturday" },
]

const timezoneOptions = [
  { label: "Alaska", value: "Alaska" },
  { label: "Hawaii", value: "Hawaii" },
  { label: "Pacific Time", value: "Pacific" },
  { label: "Arizona", value: "Arizona" },
  { label: "Mountain Time", value: "Mountain" },
  { label: "Central Time", value: "Central" },
  { label: "Eastern Time", value: "Eastern" },
]

const statusOptions = [
  { value: "initial", label: "Initial" },
  { value: "requested", label: "Requested" },
  { value: "scheduled", label: "Scheduled" },
  { value: "substitute_requested", label: "Substitute Requested" },
  { value: "canceled", label: "Canceled" },
  { value: "declined", label: "Declined" },
]

const billOptions = [
  { value: "unbilled", label: "Unbilled" },
  { value: "pending", label: "Pending" },
  { value: "billed", label: "Billed" },
  { value: "failed", label: "Failed" },
  { value: "comped", label: "Comped" },
]

const payerOptions = [
  { value: "Organization", label: "Organization" },
  { value: "Student", label: "Student" },
]

const durationChangeOptions = [
  { value: "unchanged", label: "Unchanged" },
  { value: "approval_needed", label: "Approval Needed" },
  { value: "approved", label: "Approved" },
  { value: "denied", label: "Denied" },
]

const courseTypeOptions = [
  { value: "Academic", label: "Academic" },
  { value: "Enrichment", label: "Enrichment" },
]

const collaborationTypeOptions = [
  { label: "Realtimeboard", value: "realtimeboard" },
  { label: "Google Doc", value: "googledoc" },
  { label: "Both", value: "both" },
]

const publishedOptions = [
  { value: "all", label: "All" },
  { value: "true", label: "Published" },
  { value: "false", label: "Hidden" },
]

const proxyStateOptions = [
  { value: "", label: "All" },
  { value: "open", label: "Open" },
  { value: "closed", label: "Closed" },
  { value: "initial", label: "Setup Error" },
]

const conversationTypeOptions = [
  { value: "", label: "All" },
  { value: "parent", label: "Parent" },
  { value: "student", label: "Student" },
]

const participationLevelOptions = [
  { label: "Absent", value: "absent" },
  { label: "No Participation", value: "none" },
  { label: "Participated Somewhat", value: "moderate" },
  { label: "Great Effort!", value: "full" },
]

const tutorAssessmentOptions = [
  { label: "Absent", value: "absent" },
  { label: "Fails to meet expectations", value: "fails_expectations" },
  { label: "Partially meets expectations", value: "partial_expectations" },
  { label: "Meets expectations", value: "meets_expectations" },
  { label: "Exceeds expectations", value: "exceeds_expectations" },
]

const studentAssessmentOptions = [
  { label: "Don’t Understand", value: "no_understanding" },
  { label: "Kinda Understand", value: "some_understanding" },
  { label: "Understood Completely", value: "full_understanding" },
]

const subjectTypeOptions = [
  { label: "Math", value: "math" },
  { label: "Science", value: "science" },
  { label: "English", value: "english" },
  { label: "History/Social Studies", value: "social_studies" },
  { label: "Languages", value: "languages" },
  { label: "Other", value: "other" },
]

const studentAssessmentOptionsWithIcons = [
  {
    label: (
      <div className="mt-[-3px] flex">
        <FontAwesomeIcon
          icon={faFrown}
          className="mr-3 text-3xl text-red-600"
        />
        <span>Don’t Understand</span>
      </div>
    ),
    value: "no_understanding",
  },
  {
    label: (
      <div className="mt-[-3px] flex">
        <FontAwesomeIcon
          icon={faMeh}
          className="mr-3 text-3xl text-yellow-600"
        />
        <span>Kinda Understand</span>
      </div>
    ),
    value: "some_understanding",
  },
  {
    label: (
      <div className="mt-[-3px] flex">
        <FontAwesomeIcon
          icon={faSmile}
          className="mr-3 text-3xl text-green-600"
        />
        <span>Completely Understand</span>
      </div>
    ),
    value: "full_understanding",
  },
]

const expenseTypeOptions = [
  { label: "Fingerprinting", value: "fingerprinting" },
  { label: "New Tutor Intro ($20)", value: "intro_session" },
  { label: "Other", value: "other" },
]

const expenseStateOptions = [
  { label: "Submitted", value: "submitted" },
  { label: "Changes Requested", value: "changes_requested" },
  { label: "Denied", value: "denied" },
  { label: "Approved", value: "approved" },
]

const statementStateOptions = [
  { label: "Pending", value: "pending" },
  { label: "Generated", value: "generated" },
  { label: "Submitted", value: "submitted" },
  { label: "Payment Received", value: "payment_received" },
]

const degreeLevelOptions = [
  { label: "Undergraduate", value: "undergraduate" },
  { label: "Graduate", value: "graduate" },
  { label: "PhD/Doctorate", value: "doctorate" },
  { label: "Other", value: "other" },
]

const degreeTypeOptions = [
  "BA (Bachelor of Arts)",
  "BS (Bachelor of Science)",
  "BFA (Bachelor of Fine Arts)",
  "MA",
  "MS",
  "MPA",
  "M.Ed",
  "MBA",
  "MFA",
  "Ed.D",
  "JD",
  "PhD",
  "MD",
  "DDS",
  "N/A",
  "Other",
].map(type => ({ label: type, value: type }))

const foreignLanguageFluencyOptions = [
  { label: "Basic (can write a language)", value: "basic" },
  {
    label: "Conversational Speech (Can speak a language well)",
    value: "conversational_speech",
  },
  {
    label:
      "Conversational Speech and Writing (can write and speak a language well)",
    value: "conversational_speech_and_writing",
  },
  {
    label: "Fluent (can write and speak a language almost perfectly)",
    value: "fluent",
  },
  {
    label:
      "Native Speaker/Bilingual (can write and speak a language perfectly, including colloquialisms)",
    value: "native",
  },
  { label: "N/A", value: "n/a" },
]

const tutorApplicationStates = [
  { label: "All", value: "" },
  { label: "Draft", value: "draft" },
  { label: "Auto-Rejected", value: "auto_rejected" },
  { label: "Resume Submitted", value: "resume_submitted" },
  { label: "Under Resume Review", value: "under_resume_review" },
  { label: "Pending Selfie", value: "pending_selfie" },
  { label: "Selfie Submitted", value: "selfie_submitted" },
  { label: "Under Selfie Review", value: "under_selfie_review" },
  { label: "Resume Rejected", value: "resume_rejected" },
  { label: "Pending Interview", value: "pending_interview" },
  { label: "Selfie Rejected", value: "selfie_rejected" },
  { label: "Interview Scheduled", value: "interview_scheduled" },
  { label: "Interview Rejected", value: "interview_rejected" },
  { label: "Pending Final Approval", value: "pending_final_approval" },
  { label: "Application Approved", value: "approved" },
  { label: "Deferred", value: "deferred" },
]

const tutorApplicationReferralSources = [
  { label: "Flyer", value: "flyer" },
  { label: "Job Fair", value: "job_fair" },
  { label: "Recommended by Air Tutors Employee", value: "airtutors_employee" },
  { label: "Referral", value: "referral" },
  { label: "Indeed", value: "indeed" },
  { label: "LinkedIn", value: "linkedin" },
  { label: "Instagram", value: "instagram" },
  { label: "Facebook", value: "facebook" },
  { label: "Other", value: "other" },
]

const countryOptions = ["Other", "United States"].map(country => ({
  label: country,
  value: country,
}))

const regionOptions = [
  "Other",
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
].map(region => ({ label: region, value: region }))

const raceOptions = [
  "American Indian or Alaska Native",
  "Asian",
  "Black or African American",
  "Native Hawaiian or Other Pacific Islander",
  "White",
  "Biracial/Multiracial",
  "I do not wish to disclose",
  "Other",
].map(race => ({ label: race, value: race }))

const ethnicityOptions = [
  "Hispanic",
  "Not Hispanic",
  "I do not wish to disclose",
].map(ethnicity => ({
  label: ethnicity,
  value: ethnicity,
}))

const teachingExperienceOptions = [
  { label: "Yes, comfortable & experienced", value: "experienced" },
  { label: "Willing to but inexperienced", value: "inexperienced" },
  { label: "Not willing to teach level", value: "unwilling" },
  { label: "Other", value: "other" },
]
const buildTeachingExperienceOption = level => [
  { label: "Yes, comfortable & experienced", value: "experienced" },
  { label: "Willing to but inexperienced", value: "inexperienced" },
  { label: `Not willing to teach at ${level} level`, value: "unwilling" },
  { label: "Other", value: "other" },
]

export {
  billOptions,
  buildTeachingExperienceOption,
  collaborationTypeOptions,
  conversationTypeOptions,
  countryOptions,
  courseTypeOptions,
  degreeLevelOptions,
  degreeTypeOptions,
  durationChangeOptions,
  ethnicityOptions,
  expenseStateOptions,
  expenseTypeOptions,
  foreignLanguageFluencyOptions,
  participationLevelOptions,
  payerOptions,
  proxyStateOptions,
  publishedOptions,
  raceOptions,
  regionOptions,
  statementStateOptions,
  statusOptions,
  studentAssessmentOptions,
  studentAssessmentOptionsWithIcons,
  subjectTypeOptions,
  teachingExperienceOptions,
  timezoneOptions,
  tutorApplicationReferralSources,
  tutorApplicationStates,
  tutorAssessmentOptions,
  weekdayOptions,
}
