import React, { useEffect, useState } from "react"

import PropTypes from "prop-types"

import axios from "src/axios"

import TailoredSectionForm from "components/TailoredSectionForm"

const TutorTailoredSectionFormContainer = props => {
  const { id } = props
  const [section, setSection] = useState(null)

  useEffect(() => {
    axios.get(`/api/tutors/tailored_sections/${id}`).then(response => {
      const section = response.data
      setSection(section)
    })
  }, [id])

  if (!section) return null

  return (
    <TailoredSectionForm
      apiNamespace="tutors"
      id={id}
      maxQuestions={section.maxQuestions}
      tags={section.tags}
      formState={{ questionIds: section.tailoredQuestionIds }}
    />
  )
}

TutorTailoredSectionFormContainer.propTypes = {
  id: PropTypes.number.isRequired,
}

export default TutorTailoredSectionFormContainer
