import React, { useEffect, useState } from "react"

import PropTypes from "prop-types"

import axios from "src/axios"

import TailoredAssignmentForm from "components/TailoredAssignmentForm"

const TutorTailoredAssignmentFormContainer = props => {
  const { id } = props
  const [initialFormState, setInitialFormState] = useState({
    name: "",
    sections: [],
    studentId: props.studentId,
  })

  useEffect(() => {
    if (id) {
      axios.get(`/api/tutors/tailored_assignments/${id}`).then(response => {
        const assignment = response.data
        assignment.sections.forEach(s => (s.tagSearch = ""))
        setInitialFormState(assignment)
      })
    }
  }, [id])

  return (
    <TailoredAssignmentForm
      id={id}
      initialFormState={initialFormState}
      apiNamespace="tutors"
    />
  )
}

TutorTailoredAssignmentFormContainer.propTypes = {
  id: PropTypes.number,
}

export default TutorTailoredAssignmentFormContainer
