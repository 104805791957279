import React, { useState } from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import { gql } from "@urql/core"

import airbrake from "src/airbrake-notifier"
import { countryOptions, regionOptions } from "src/enums"

import { useMutation } from "hooks/urql"

import {
  AutocompleteField,
  CheckboxField,
  Field,
  FileField,
  FormStatus,
  SelectField,
  SubmitButton,
  TextAreaField,
  TimeZoneField,
  readFile,
} from "components/Forms/Formik/hookComponents"
import SlackChannelHint from "components/SlackChannelHint"

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required(),
  slug: Yup.string().required(),
  minimumGroupSize: Yup.number().required(
    "Minimum group size is required. Set to 0 to disable this feature."
  ),
})

const OrganizationForm = ({ organization }) => {
  const formState = {
    id: organization?.id,
    name: organization?.name || "",
    slug: organization?.slug || "",
    emailTimeZone: organization?.emailTimeZone || "Pacific",
    details: organization?.details || "",
    calendarEmbedUrl: organization?.calendarEmbedUrl || "",
    chimeCloudroomEnabled: organization
      ? organization.chimeCloudroomEnabled
      : false,
    lowBandwidthCloudroom: organization
      ? organization.lowBandwidthCloudroom
      : false,
    disableRealtimeboardInvites: organization
      ? organization.disableRealtimeboardInvites
      : false,
    disableStudentNotifications: organization
      ? organization.disableStudentNotifications
      : false,
    automatedTwilioSessionsEnabled: organization
      ? organization.automatedTwilioSessionsEnabled
      : true,
    sessionRecordingEnabled: organization
      ? organization.sessionRecordingEnabled
      : false,
    screenshareRecordingEnabled: organization
      ? organization.screenshareRecordingEnabled
      : false,
    studentScreenShareDisabled: organization
      ? organization.studentScreenShareDisabled
      : false,
    reassignStudentsOnMissingTutor: organization
      ? organization.reassignStudentsOnMissingTutor
      : true,
    billingEnabled: organization ? organization.billingEnabled : true,
    paysByCheck: organization ? organization.paysByCheck : true,
    hourlyRate: organization?.hourlyRate || "",
    allowLongSessions: organization ? organization.allowLongSessions : false,
    noShowRate: organization?.noShowRate || "",
    allowLongNoShows: organization ? organization.allowLongNoShows : false,
    perStudentConcurrentCourseLimit:
      organization?.perStudentConcurrentCourseLimit || 2,
    studentOneOnOneHoursWeeklyLimit:
      organization?.studentOneOnOneHoursWeeklyLimit || "",
    courseGroupLimit: organization?.courseGroupLimit || 100,
    minimumGroupSize: organization?.minimumGroupSize || 0,
    omitStatementBalance: organization?.omitStatementBalance || false,
    addressLine: organization?.addressLine || "",
    attention: organization?.attention || "",
    intakeBlurb: organization?.intakeBlurb || "",
    simpleIntakeBlurb: organization?.simpleIntakeBlurb || "",
    minimumAvailabilityWindows: organization?.minimumAvailabilityWindows || 6,
    allowedDomains: organization ? organization.allowedDomains.join(", ") : "",
    intakeParentRequired: organization
      ? organization.intakeParentRequired
      : true,
    cleverDistrictId: organization?.cleverDistrictId || "",
    classlinkDistrictId: organization?.classlinkDistrictId || "",
    slackChannelId: organization?.slackChannelId || "",
    country: organization ? organization.address?.country : "United States",
    region: organization ? organization.address?.region : "California",
    sessionTimeoutMinutes: organization
      ? organization.sessionTimeoutMinutes
      : 0,
    minTutorConsistencyRate: organization?.minTutorConsistencyRate || 70,
    participationDigestEnabled:
      organization?.participationDigestEnabled || false,
    participationDigestRecipients:
      organization?.participationDigestRecipients.join(", ") || "",
  }
  const [errors, setErrors] = useState([])
  const [, saveOrganization] = useMutation(saveOrganizationMutation)

  const handleSubmit = (values, actions) => {
    const {
      allowedDomains,
      participationDigestRecipients,
      logo,
      ...variables
    } = values
    if (allowedDomains) {
      variables.allowedDomains = allowedDomains.split(",").map(s => s.trim())
    }
    if (participationDigestRecipients) {
      variables.participationDigestRecipients = participationDigestRecipients
        .split(",")
        .map(s => s.trim())
    }
    if (variables.hourlyRate === "") delete variables.hourlyRate
    if (variables.noShowRate === "") delete variables.noShowRate
    if (variables.minTutorConsistencyRate === "")
      delete variables.minTutorConsistencyRate
    if (variables.sessionTimeoutMinutes === "")
      variables.sessionTimeoutMinutes = 0
    if (variables.perStudentConcurrentCourseLimit === "")
      delete variables.perStudentConcurrentCourseLimit
    if (variables.studentOneOnOneHoursWeeklyLimit === "")
      delete variables.studentOneOnOneHoursWeeklyLimit
    if (variables.courseGroupLimit === "") delete variables.courseGroupLimit
    if (variables.classlinkDistrictId === "")
      delete variables.classlinkDistrictId

    readFile(logo)
      .then(file => {
        variables.logo = file
        saveOrganization({ input: variables })
          .then(response => {
            const { failures, redirectTo } = response.data.organizationSave
            if (failures.length > 0) {
              setErrors(failures)
            } else {
              window.location = redirectTo
            }
          })
          .catch(error => {
            setErrors([{ message: "Something went wrong" }])
            airbrake.notify({
              error,
              params: { name: "AdminOrganizationForm#saveOrganization" },
            })
          })
          .finally(() => actions.setSubmitting(false))
      })
      .catch(error => {
        setErrors([{ message: "Something went wrong" }])
        airbrake.notify({
          error,
          params: { name: "AdminOrganizationForm#readFile" },
        })
      })
  }

  return (
    <div className="w-full md:w-2/3 lg:w-1/2">
      <Formik
        initialValues={formState}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Field name="name" label="Name" />
          <Field
            name="slug"
            label={
              <>
                Slug
                <div className="italic">
                  This will become part of the organization's unique student
                  registration URL.
                  <br /> Changing it will make the old URL invalid.
                </div>
              </>
            }
          />
          <p className="italic"></p>
          <TimeZoneField name="emailTimeZone" label="Time Zone" />
          <TextAreaField name="details" label="Details" />
          <Field
            name="calendarEmbedUrl"
            label={
              <>
                Calendar Embed URL
                <div className="italic">
                  (copy the stuff between the quotes after src=)
                </div>
              </>
            }
          />
          {organization ? (
            <>
              <AutocompleteField
                name="primaryAdminId"
                label={
                  <>
                    Primary Admin
                    <div className="italic">
                      This is the user that will be emailed for things like
                      billing issues.
                    </div>
                  </>
                }
                placeholder="Search by email"
                api="/api/admins/organization_admins/autocomplete_email"
                searchOptions={`organization_id=${organization.id}`}
                defaultValue={
                  organization?.primaryAdmin
                    ? {
                        value: organization.primaryAdmin.id,
                        label: organization.primaryAdmin.email,
                      }
                    : null
                }
              />
            </>
          ) : null}

          <CheckboxField
            name="chimeCloudroomEnabled"
            label="Use Chime Cloudrooms?"
          />
          <CheckboxField
            name="lowBandwidthCloudroom"
            label="Low Bandwidth Cloudroom"
          />
          <CheckboxField
            name="disableRealtimeboardInvites"
            label="Don't create student Miro accounts. Students will only have view access to boards."
          />
          <CheckboxField
            name="disableStudentNotifications"
            label="Disable Student Notifications"
          />
          <CheckboxField
            name="automatedTwilioSessionsEnabled"
            label="Automatically Generate Twilio Numbers"
          />
          <CheckboxField
            name="sessionRecordingEnabled"
            label="Session Audio Recording Enabled"
          />
          <CheckboxField
            name="screenshareRecordingEnabled"
            label="Screenshare Recording Enabled"
          />
          <CheckboxField
            name="studentScreenShareDisabled"
            label="Student Screenshare Disabled"
          />
          <CheckboxField
            name="reassignStudentsOnMissingTutor"
            label="Reassign Students when tutor missing from session (this will also auto-generate sub requests)"
          />
          <CheckboxField name="billingEnabled" label="Billing Enabled" />
          <CheckboxField name="paysByCheck" label="Pays by Check" />
          <Field
            name="hourlyRate"
            label="Hourly Rate"
            type="number"
            step={0.01}
          />
          <CheckboxField
            name="allowLongSessions"
            label="Allow Sessions Longer Than 1 Hour"
          />
          <Field
            name="noShowRate"
            label="No Show Rate"
            type="number"
            step={0.01}
          />
          <CheckboxField
            name="allowLongNoShows"
            label="Allow No Shows Longer Than 1 Hour"
          />
          <Field
            name="perStudentConcurrentCourseLimit"
            label="Student concurrent course limit"
            type="number"
          />
          <Field
            name="studentOneOnOneHoursWeeklyLimit"
            label="Student one-one weekly hours limit"
            type="number"
            step={0.01}
          />
          <Field
            name="courseGroupLimit"
            label="Course Group Limit"
            type="number"
          />
          <Field
            name="minimumGroupSize"
            label="Minimum Group Size (will prevent scheduling of group sessions with fewer than this many students)"
            type="number"
          />
          <Field
            name="minTutorConsistencyRate"
            label="Required Tutor Consistency Rate (will prevent tutors from requestings subs for more than this percentage of the group's sessions)"
            type="number"
          />
          <SelectField
            name="country"
            label="Country"
            options={countryOptions}
            defaultValue={countryOptions.find(
              o => o.value === formState.country
            )}
          />
          <SelectField
            name="region"
            label="Region"
            options={regionOptions}
            defaultValue={regionOptions.find(o => o.value === formState.region)}
          />
          <Field
            name="sessionTimeoutMinutes"
            label="Login Timeout (in minutes, 0 means use application default of 1 week)"
            type="number"
            step={1}
            min={0}
          />

          <h4>Invoice Fields</h4>
          <FileField name="logo" label="Logo" />
          <TextAreaField name="addressLine" label="Address" />
          <TextAreaField name="attention" label="Attention" />
          <CheckboxField
            name="omitStatementBalance"
            label="Omit Balance from Statement"
          />

          <h4>Intake Fields</h4>
          <TextAreaField name="intakeBlurb" label="Intake Blurb" />
          <TextAreaField name="simpleIntakeBlurb" label="Simple Intake Blurb" />
          <Field
            name="minimumAvailabilityWindows"
            label="Minimum Availability Windows"
            type="number"
          />
          <Field
            name="allowedDomains"
            label={
              <>
                Allowed Domains
                <div className="italic">
                  Comma separated list of allowed student domains ie,
                  google.com, hotmail.com
                </div>
              </>
            }
          />
          <CheckboxField
            name="intakeParentRequired"
            label="Intake Parent Required"
          />

          <CheckboxField
            name="participationDigestEnabled"
            label="Participation Digest Enabled?"
          />
          <Field
            name="participationDigestRecipients"
            label={
              <>
                Participation Digest Recipients
                <div className="italic">Comma separated list of emails</div>
              </>
            }
          />

          <h4>Miscellaneous Configuration</h4>
          <Field name="cleverDistrictId" label="Clever District ID" />
          <Field
            name="classlinkDistrictId"
            type="number"
            label="ClassLink District ID"
          />
          <Field name="slackChannelId" label="Slack Channel ID" />
          <SlackChannelHint />

          {errors.map((error, index) => (
            <div key={index} className="alert alert-danger">
              {error.message}
            </div>
          ))}
          <FormStatus />
          <SubmitButton />
        </Form>
      </Formik>
    </div>
  )
}

const saveOrganizationMutation = gql`
  mutation ($input: OrganizationInputObject!) {
    organizationSave(input: $input) {
      redirectTo
      failures {
        message
      }
    }
  }
`

export default OrganizationForm
