import React from "react"

import ReactModal from "react-modal"

import { css } from "@emotion/core"

import { modalHOC } from "components/Modal"

class ModalWithProvidedBody extends React.Component {
  render() {
    let modalContent = (
      <div dangerouslySetInnerHTML={{ __html: this.props.content }} />
    )
    if (this.props.reactContent) {
      modalContent = (
        <div
          css={css`
            display: contents;
          `}
        >
          {this.props.reactContent}
        </div>
      )
    } else if (this.props.children) {
      modalContent = (
        <div
          css={css`
            display: contents;
          `}
        >
          {typeof this.props.children === "function"
            ? this.props.children({ closeModal: this.props.closeModal })
            : this.props.children}
        </div>
      )
    }

    let modalTrigger
    if (!this.props.hideTrigger)
      modalTrigger = (
        <button
          type="button"
          onClick={this.props.openModal}
          className={this.props.buttonClassName}
        >
          {this.props.buttonText}
        </button>
      )
    let closeButton
    if (!this.props.hideCloseButton) {
      closeButton = (
        <button
          type="button"
          className="close"
          onClick={this.props.closeModal}
          aria-label="Close"
          tabIndex="-1"
        >
          <span aria-hidden="true">
            <i className="nc-icon nc-simple-remove text-danger" />
          </span>
        </button>
      )
    }

    return (
      <React.Fragment>
        {modalTrigger}
        <ReactModal
          isOpen={this.props.isOpen}
          onAfterOpen={this.props.afterOpenModal}
          onRequestClose={this.props.onRequestClose || this.props.closeModal}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          contentLabel={this.props.buttonClassName}
          bodyOpenClassName="modal-open"
          ariaHideApp={false}
          className={`modal show ${this.props.modalClassName}`}
        >
          <div
            className={`modal-dialog ${this.props.modalDialogClassName}`}
            role="document"
          >
            <div
              className="modal-content"
              css={css`
                max-height: 80vh;
                overflow-y: scroll;

                .modal-body {
                  overflow-y: scroll;
                }

                form {
                  display: contents;
                }
              `}
            >
              <div className="modal-header">
                <h2
                  className="modal-title"
                  css={css`
                    font-size: 1.5em;
                    margin: 0;
                  `}
                  ref={subtitle => (this.subtitle = subtitle)}
                >
                  {this.props.modalTitle}
                </h2>
                {closeButton}
              </div>
              {modalContent}
            </div>
          </div>
        </ReactModal>
      </React.Fragment>
    )
  }
}

ModalWithProvidedBody.defaultProps = {
  modalClassName: "",
  modalDialogClassName: "",
}

export default modalHOC(ModalWithProvidedBody)
