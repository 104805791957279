import React, { useEffect, useState } from "react"

import { DefaultModality } from "amazon-chime-sdk-js"

import CloudroomContext from "components/ChimeCloudroom/CloudroomContext"
import GoogleDocButton from "components/ChimeCloudroom/Shared/GoogleDocButton"
import LocalTile from "components/ChimeCloudroom/Shared/LocalTile"
import MiroButton from "components/ChimeCloudroom/Shared/MiroButton"
import RemoteTile from "components/ChimeCloudroom/Shared/RemoteTile"
import useChimeCloudroom from "components/ChimeCloudroom/hooks/useChimeCloudroom"
import useCloudroomControls from "components/ChimeCloudroom/hooks/useCloudroomControls"

import EndSession from "./EndSession"
import ScreenShareButton from "./ScreenShareButton"
import StudentScreenShareMessage from "./ScreenShareMessage"

export type StudentLayoutProps = {
  session: any
  localUser: {
    type: "Student" | "Teacher" | "Admin"
    id: string
    ssoEmail: string
    ssoEnabled: boolean
  }
  ghost?: boolean
}

const StudentLayout: React.FC<StudentLayoutProps> = ({
  session,
  localUser,
  ghost,
}) => {
  const [roster, setRoster] = useState<Record<string, any>>({})
  const cloudroomInfo = useChimeCloudroom({
    session,
    localUser,
    setRoster,
    ghost,
  })
  const controls = useCloudroomControls({
    audioVideo: cloudroomInfo.audioVideo,
    session,
    localUser,
    ghost,
    roster,
  })
  const { reattachVideoElements } = controls

  const remoteAttendeeIds = Object.keys(roster).filter(
    id =>
      new DefaultModality(id).base() !==
      session.chimeJoinInfo.attendee.attendeeId
  )

  let primaryAttendeeId = remoteAttendeeIds.find(
    id => roster[id].sharingContent
  )

  if (!primaryAttendeeId)
    primaryAttendeeId = remoteAttendeeIds.find(
      id => roster[id].externalUserId.split("-")[0] === "Tutor"
    )

  useEffect(() => {
    reattachVideoElements()
  }, [primaryAttendeeId, reattachVideoElements])

  const layout =
    remoteAttendeeIds.filter(id => id !== primaryAttendeeId).length === 0
      ? "single"
      : "grid"

  return (
    <CloudroomContext.Provider
      value={{ ...cloudroomInfo, ...controls, roster, localUser }}
    >
      <StudentScreenShareMessage />

      <div className="grid h-screen grid-areas-student-slim sm:grid-areas-student-wide">
        <main className="relative p-2 grid-in-main">
          {["Admin", "Teacher"].includes(localUser.type) && (
            <div className="absolute top-2 left-2">
              <EndSession
                airtutorsSessionId={session.id}
                userType={localUser.type}
              />
            </div>
          )}
          <div className="flex h-full flex-col justify-center">
            {primaryAttendeeId && <RemoteTile attendeeId={primaryAttendeeId} />}
          </div>
        </main>
        <aside className="flex max-w-[500px] flex-col justify-between p-2 grid-in-aside">
          <div className={layout === "grid" ? "grid grid-cols-2 gap-x-2" : ""}>
            <LocalTile />
            {remoteAttendeeIds.map(id =>
              id === primaryAttendeeId ? null : (
                <RemoteTile
                  attendeeId={id}
                  key={id}
                  numAttendees={remoteAttendeeIds.length}
                />
              )
            )}
          </div>

          <div className="mb-1 flex flex-col items-start">
            <MiroButton session={session} user={localUser} />
            <GoogleDocButton
              googledocUrl={session.googledocUrl}
              userType={localUser.type}
            />
            {!ghost && <ScreenShareButton />}
          </div>
        </aside>
      </div>
    </CloudroomContext.Provider>
  )
}

export default StudentLayout
