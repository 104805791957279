import React from "react"

const NameSearch = ({ setSearchParams }) => {
  const handleInputChange = e => {
    setSearchParams({ fullName: e.currentTarget.value })
  }

  return (
    <div className="form-group">
      <label className="form-label w-100">
        Search by name
        <input className="form-control" onChange={handleInputChange} />
      </label>
    </div>
  )
}

export default NameSearch
