import React, { useContext } from "react"

import {
  faDesktop,
  faLock,
  faLockOpen,
  faMicrophone,
  faMicrophoneSlash,
  faVideo,
  faVideoSlash,
} from "@fortawesome/free-solid-svg-icons"

import CloudroomContext from "components/ChimeCloudroom/CloudroomContext"
import BaseButton from "components/ChimeCloudroom/Shared/BaseButton"
import GoogleDocButton from "components/ChimeCloudroom/Shared/GoogleDocButton"
import MiroButton from "components/ChimeCloudroom/Shared/MiroButton"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

import CompleteSessionModal from "./CompleteSessionModal"
import CompleteWorksheetButtons from "./CompleteWorksheetButtons"
import SendSmsButton from "./SendSmsButton"

export type ControlsProps = Record<string, never>

const Controls: React.FC<ControlsProps> = () => {
  const {
    session,
    localUser,
    roster,
    channel,
    allAudioMuted,
    toggleAllAudio,
    allVideoMuted,
    toggleAllVideo,
    allControlsLocked,
    toggleAllControlsLocked,
    startLocalScreenShare,
    stopLocalScreenShare,
  } = useContext(CloudroomContext)

  const screenSharing =
    roster[session.chimeJoinInfo.attendee.attendeeId]?.sharingContent

  return (
    <div className="grid grid-cols-2 gap-x-2 sm:grid-cols-1">
      <BaseButton
        active={allAudioMuted}
        onClick={toggleAllAudio}
        activeText="Unmute Students"
        inactiveText="Mute Students"
        activeIcon={faMicrophoneSlash}
        inactiveIcon={faMicrophone}
        className="mb-1 w-full justify-between leading-loose hover:opacity-75"
      />
      <BaseButton
        active={allVideoMuted}
        onClick={toggleAllVideo}
        activeText="Enable Student Video"
        inactiveText="Disable Student Video"
        activeIcon={faVideoSlash}
        inactiveIcon={faVideo}
        className="mb-1 w-full justify-between leading-loose hover:opacity-75"
      />
      <BaseButton
        active={allControlsLocked}
        onClick={toggleAllControlsLocked}
        activeText="Unlock Student Controls"
        inactiveText="Lock Student Controls"
        activeIcon={faLock}
        inactiveIcon={faLockOpen}
        className="mb-1 w-full justify-between leading-loose hover:opacity-75"
      />
      <BaseButton
        active={screenSharing}
        onClick={screenSharing ? stopLocalScreenShare : startLocalScreenShare}
        activeText="Stop Sharing Your Screen"
        inactiveText="Share Your Screen"
        activeIcon={faDesktop}
        inactiveIcon={faDesktop}
        className="mb-1 w-full justify-between leading-loose hover:opacity-75"
      />

      <CompleteWorksheetButtons airtutorsSessionId={session.id} />

      <MiroButton user={localUser} session={session} />

      <GoogleDocButton
        googledocUrl={session.googledocUrl}
        userType={localUser.type}
      />

      <a
        href={session.studyGroupPath || session.studentPath}
        className="btn solid red mb-1 w-full"
        target="_blank"
        rel="noopener noreferrer"
      >
        Previous Sessions
      </a>

      <ModalWithProvidedBody
        buttonClassName="btn solid blue w-100 mb-1"
        buttonText="End Session"
        modalClassName="bootstrap-modal"
        modalTitle="End Session"
      >
        {({ closeModal }) => (
          <CompleteSessionModal
            id={session.id}
            endsAt={session.endsAt}
            closeModal={closeModal}
            channel={channel}
          />
        )}
      </ModalWithProvidedBody>

      <SendSmsButton airtutorsSessionId={session.id} />
    </div>
  )
}

export default Controls
