import React, { Fragment, useState } from "react"

import logo from "assets/airtutors_banner.png"
import { NavLink } from "react-router-dom"
import { gql, useQuery } from "urql"

import { Dialog, Menu, Transition } from "@headlessui/react"
import {
  ChevronDownIcon,
  ComputerDesktopIcon,
  UserGroupIcon,
} from "@heroicons/react/20/solid"
import {
  AcademicCapIcon,
  Bars3Icon,
  Cog6ToothIcon,
  HomeIcon,
  UserCircleIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline"

import WithLoadingIndicator from "~tailwindui/shared/WithLoadingIndicator"

import classNames from "src/classNames"

const navigation = [
  { name: "Dashboard", href: "/dashboard", icon: HomeIcon },
  { name: "Schools", href: "/schools", icon: AcademicCapIcon },
  { name: "No Shows", href: "/no_shows", icon: XMarkIcon },
  { name: "Students", href: "/students", icon: UsersIcon },
  { name: "Sessions", href: "/sessions", icon: ComputerDesktopIcon },
  { name: "Study Groups", href: "/study_groups", icon: UserGroupIcon },
  { name: "Users", href: "/users", icon: UserCircleIcon },
]

const Sidebar = ({ logout, children }) => {
  const [result] = useQuery({ query: currentUserQuery })
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <WithLoadingIndicator result={result}>
      {({ data }) => (
        <>
          <div>
            <Transition.Root show={sidebarOpen} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-50 lg:hidden"
                onClose={setSidebarOpen}
              >
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-900/80" />
                </Transition.Child>

                <div className="fixed inset-0 flex">
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                  >
                    <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                          <button
                            type="button"
                            className="-m-2.5 p-2.5"
                            onClick={() => setSidebarOpen(false)}
                          >
                            <span className="sr-only">Close sidebar</span>
                            <XMarkIcon
                              className="h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </Transition.Child>
                      {/* Sidebar component, swap this element with another sidebar if you like */}
                      <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-brand-600 px-6 pb-4">
                        <div className="mt-4 flex h-16 shrink-0 items-center justify-center rounded bg-brand-50 p-4">
                          <img
                            className="h-8 w-auto"
                            src={logo}
                            alt="Your Company"
                          />
                        </div>
                        <nav className="flex flex-1 flex-col">
                          <ul className="flex flex-1 flex-col gap-y-7">
                            <li>
                              <ul className="-mx-2 space-y-1">
                                {navigation.map(item => (
                                  <li key={item.name}>
                                    <NavLink
                                      to={item.href}
                                      onClick={() => setSidebarOpen(false)}
                                      className={current =>
                                        classNames(
                                          current
                                            ? "is-current group bg-brand-700 text-white"
                                            : "not-current text-brand-200 hover:bg-brand-700 hover:text-white",
                                          "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                                        )
                                      }
                                    >
                                      <item.icon
                                        className={classNames(
                                          "group-[.is-current]:text-white group-[.is-current]:group group-[.not-current]:text-brand-200 h-6 w-6 shrink-0 group-hover:text-white"
                                        )}
                                        aria-hidden="true"
                                      />
                                      {item.name}
                                    </NavLink>
                                  </li>
                                ))}
                              </ul>
                            </li>
                            <li className="mt-auto">
                              <NavLink
                                to="/settings"
                                className={current =>
                                  classNames(
                                    current
                                      ? "is-current group bg-brand-700 text-white"
                                      : "text-brand-200 hover:bg-brand-700 hover:text-white",
                                    "group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                                  )
                                }
                              >
                                <Cog6ToothIcon
                                  className="group-[.is-current]:text-white group-[.not-current]:text-brand-200 h-6 w-6 shrink-0 group-hover:text-white"
                                  aria-hidden="true"
                                />
                                Settings
                              </NavLink>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-brand-600 px-6 pb-4">
                <div className="mt-4 flex h-16 shrink-0 items-center justify-center rounded bg-brand-50 p-4">
                  <img
                    className="-ml-3 h-8 w-auto"
                    src={logo}
                    alt="Your Company"
                  />
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul className="-mx-2 space-y-1">
                        {navigation.map(item => (
                          <li key={item.name}>
                            <NavLink
                              to={item.href}
                              className={current =>
                                classNames(
                                  current
                                    ? "is-current group bg-brand-700 text-white"
                                    : "not-current text-brand-200 hover:bg-brand-700 hover:text-white",
                                  "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                                )
                              }
                            >
                              <item.icon
                                className="group-[.is-current]:text-white group-[.not-current]:text-brand-200 h-6 w-6 shrink-0 group-hover:text-white"
                                aria-hidden="true"
                              />
                              {item.name}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li className="mt-auto">
                      <NavLink
                        to="/settings"
                        className={current =>
                          classNames(
                            current
                              ? "is-current bg-brand-700 text-white"
                              : "not-current text-brand-200 hover:bg-brand-700 hover:text-white",
                            "group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                          )
                        }
                      >
                        <Cog6ToothIcon
                          className="group-[.is-current]:text-white group-[.not-current]:text-brand-200 h-6 w-6 shrink-0 group-hover:text-white"
                          aria-hidden="true"
                        />
                        Settings
                      </NavLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>

            <div className="lg:pl-72">
              <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
                <button
                  type="button"
                  className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                  onClick={() => setSidebarOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Separator */}
                <div
                  className="h-6 w-px bg-gray-900/10 lg:hidden"
                  aria-hidden="true"
                />

                <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                  <NavLink
                    to="/dashboard"
                    className="relative flex flex-1 items-center"
                  >
                    {data.viewer.organization.name} Dashboard
                  </NavLink>
                  <div className="flex items-center gap-x-4 lg:gap-x-6">
                    {/* Profile dropdown */}
                    <Menu as="div" className="relative">
                      <Menu.Button className="-m-1.5 flex items-center p-1.5">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full bg-gray-50"
                          src={data.viewer.avatar.thumbUrl}
                          alt="avatar"
                        />
                        <span className="hidden lg:flex lg:items-center">
                          <span
                            className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                            aria-hidden="true"
                          >
                            {data.viewer.firstName} {data.viewer.lastName}
                          </span>
                          <ChevronDownIcon
                            className="ml-2 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <NavLink
                                to="/profile"
                                className={classNames(
                                  active ? "bg-gray-50" : "",
                                  "block px-3 py-1 text-sm leading-6 text-gray-900"
                                )}
                              >
                                Edit Profile
                              </NavLink>
                            )}
                          </Menu.Item>

                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={logout}
                                className={classNames(
                                  active ? "bg-gray-50" : "",
                                  "block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900"
                                )}
                              >
                                Logout
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>

              <main className="py-10">
                <div className="px-4 sm:px-6 lg:px-8">{children}</div>
              </main>
            </div>
          </div>
        </>
      )}
    </WithLoadingIndicator>
  )
}

const currentUserQuery = gql`
  query {
    viewer {
      id
      firstName
      lastName
      avatar {
        thumbUrl
      }
      organization {
        name
      }
    }
  }
`

export default Sidebar
