import React, { useContext } from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import { findGradeOption, gradeOptionsFromTiers } from "src/grade-options"

import { gql, useClient, useMutation } from "hooks/urql"

import Checkbox from "components/Forms/Checkbox"
import { ErrorMessage } from "components/Forms/Formik"
import {
  CheckboxField,
  Field,
  FormFailures,
  SelectField,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"

import FormStateContext from "../FormStateContext"
import GradeOptionsContext from "../GradeOptionsContext"

import StudentPhoneNumberField from "./StudentPhoneNumberField"

const ValidationSchema = Yup.object().shape({
  studentFirstName: Yup.string().required("First name is required"),
  studentLastName: Yup.string().required("Last name is required"),
  studentEmail: Yup.mixed().when("studentUsername", {
    is: studentUsername => !studentUsername || studentUsername.trim() === "",
    then: Yup.string().required("Email or username is required"),
  }),
  studentPhoneNumber: Yup.string().required("Phone number is required"),
  studentPassword: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  studentPasswordConfirmation: Yup.string()
    .required("Please confirm your password")
    .equals([Yup.ref("studentPassword")], "Passwords do not match"),
  studentGradeId: Yup.string().required("Grade is required"),
  studentTermsAccepted: Yup.boolean().oneOf(
    [true],
    "You must accept the Air Tutors privacy policy"
  ),
})

const StudentProfileStep = props => {
  const client = useClient()
  const [, register] = useMutation(registerMutation, client)
  const [store, setStore] = useContext(FormStateContext)
  const { tiers } = useContext(GradeOptionsContext)

  const registerWithUsername = store.registerWithUsername || false
  const setRegisterWithUsername = value => {
    setStore({ ...store, registerWithUsername: value })
  }
  const defaultToParentPhoneNumber = store.defaultToParentPhoneNumber || false
  const parentForm = store.parentProfileFields
  const formState = store.studentProfileFields || {
    studentFirstName: "",
    studentLastName: parentForm.lastName,
    studentEmail: "",
    studentUsername: "",
    studentPhoneNumber: defaultToParentPhoneNumber
      ? parentForm.phoneNumber
      : "",
    studentPassword: "",
    studentPasswordConfirmation: "",
    studentGradeId: "",
    studentSchoolName: "",
    studentTermsAccepted: false,
  }
  const setFormState = state => {
    setStore({ ...store, studentProfileFields: state })
  }

  const gradeOptions = gradeOptionsFromTiers(tiers)

  const handleSubmit = (values, actions) => {
    setFormState(values)
    const params = { ...parentForm, ...values }
    const unusedLoginKey = registerWithUsername
      ? "studentEmail"
      : "studentUsername"
    params[unusedLoginKey] = ""
    register({ input: params })
      .then(
        result => {
          if (result.error) {
            handleFailure(actions, [result.error])
            return
          }

          const { failures, redirectTo } = result.data.parentRegistration
          if (failures) {
            handleFailure(actions, failures)
          } else {
            window.sessionStorage.removeItem("registrationWizard")
            window.location = redirectTo
          }
        },
        e => {
          if (e.message) {
            handleFailure(actions, [e])
          } else {
            handleFailure(actions)
          }
        }
      )
      .catch(e => {
        if (e.message) {
          handleFailure(actions, [e])
        } else {
          handleFailure(actions)
        }
      })
  }

  return (
    <div className="rounded-md border-2 border-solid border-gray-200 bg-gray-50 p-5">
      <h2 className="mt-0">Student Profile</h2>
      <Formik
        initialValues={formState}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Field name="studentFirstName" label="Student First Name" autoFocus />
          <Field name="studentLastName" label="Student Last Name" />
          {registerWithUsername ? (
            <>
              <Field name="studentUsername" label="Student Username" />
              <ErrorMessage
                name="studentEmail"
                className="!mt-[2px] !mb-[18px]"
              />
            </>
          ) : (
            <Field
              name="studentEmail"
              label="Student Email"
              autoComplete="email"
            />
          )}
          <Checkbox
            name="registerWithUsername"
            checked={registerWithUsername}
            onChange={e => {
              if (e.currentTarget.checked) {
                setRegisterWithUsername(true)
              } else {
                setRegisterWithUsername(false)
              }
            }}
          >
            Register with student username instead
          </Checkbox>

          <StudentPhoneNumberField />
          <SelectField
            name="studentGradeId"
            label="Grade"
            options={gradeOptions}
            defaultValue={findGradeOption(
              gradeOptions,
              formState.studentGradeId
            )}
          />
          <Field
            name="studentSchoolName"
            label="School"
            autoComplete="school"
          />
          <Field
            name="studentPassword"
            type="password"
            label="Student Password (they will use this to login to their own account)"
            autoComplete="new-password"
          />
          <Field
            name="studentPasswordConfirmation"
            type="password"
            label="Student Password Confirmation"
            autoComplete="new-password"
          />
          <CheckboxField
            name="studentTermsAccepted"
            label={
              <>
                I am giving Air Tutors permission to contact my family regarding
                setting up tutoring services and agree to the&nbsp;
                <a href="/privacy-policy" target="_blank">
                  Air Tutors privacy policy
                </a>
              </>
            }
          />

          <div className="mt-3 flex flex-col justify-between sm:flex-row">
            <button
              type="button"
              onClick={props.previousStep}
              className="btn blue mb-3 outline"
            >
              Previous: Parent Profile
            </button>
            <SubmitButton text="Complete Registration" />
          </div>
          <FormFailures />
        </Form>
      </Formik>
    </div>
  )
}

const registerMutation = gql`
  mutation ($input: ParentRegistrationInputObject!) {
    parentRegistration(input: $input) {
      failures {
        message
      }
      redirectTo
    }
  }
`

export default StudentProfileStep
