import { compress } from "micro-graphql-react"

export default compress`
  query($studentId: ID!, $page: Int!) {
    student(id: $studentId) {
      newAssignmentPath
    }
    tailoredAssignmentSummaries(studentId: $studentId, page: $page) {
      totalPages
      data {
        score
        aasmState
        resultsPath
        generateAssignmentPath
        editAssignmentPath
        tailoredAssignment {
          id
          name
        }
      }
    }
  }
`
