import React from "react"

import axios from "axios"
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom"
import { Provider } from "urql"

import SidebarLayout from "~tailwindui/SidebarLayout"

import { useClient } from "hooks/urql"

import DashboardPage from "./Pages/Dashboard"
import NoShowsPage from "./Pages/NoShows/NoShows"
import ProfilePage from "./Pages/Profile"
import SchoolDetails from "./Pages/Schools/Details"
import SchoolsPage from "./Pages/Schools/Index"
import SessionDetails from "./Pages/Sessions/Details"
import SessionsPage from "./Pages/Sessions/Index"
import SettingsPage from "./Pages/Settings"
import StudentDetails from "./Pages/Students/Details"
import StudentsPage from "./Pages/Students/Index"
import StudyGroupDetails from "./Pages/StudyGroups/Details"
import StudyGroupsPage from "./Pages/StudyGroups/Index"
import UserDetails from "./Pages/Users/Details"
import UsersPage from "./Pages/Users/Index"
import UserNew from "./Pages/Users/New"

const OrganizationAdminApp = () => {
  const client = useClient()
  const logout = () => {
    axios.delete("/users/sign_out").then(() => (window.location.href = "/"))
  }

  return (
    <Provider value={client}>
      <Router basename="/organization_admin">
        <SidebarLayout logout={logout}>
          <Switch>
            <Route exact path="/dashboard" component={DashboardPage} />
            <Route exact path="/profile" component={ProfilePage} />
            <Route exact path="/no_shows" component={NoShowsPage} />
            <Route exact path="/schools" component={SchoolsPage} />
            <Route exact path="/schools/:id" component={SchoolDetails} />
            <Route exact path="/sessions" component={SessionsPage} />
            <Route exact path="/sessions/:id" component={SessionDetails} />
            <Route exact path="/settings" component={SettingsPage} />
            <Route exact path="/students" component={StudentsPage} />
            <Route exact path="/students/:id" component={StudentDetails} />
            <Route exact path="/study_groups" component={StudyGroupsPage} />
            <Route
              exact
              path="/study_groups/:id"
              component={StudyGroupDetails}
            />
            <Route exact path="/users" component={UsersPage} />
            <Route exact path="/users/new" component={UserNew} />
            <Route exact path="/users/:id" component={UserDetails} />

            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>

            <Route>
              <div>
                <h1>404: We could not find the requested page</h1>
              </div>
            </Route>
          </Switch>
        </SidebarLayout>
      </Router>
    </Provider>
  )
}

export default OrganizationAdminApp
