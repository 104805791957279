import React, { useState } from "react"

import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const CollapsibleContainer = ({
  children,
  title,
  buttonClassName = "btn btn-sm btn-link p-2 mr-1 my-0",
  defaultCollapsed = true,
}) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed)

  return (
    <div className="mb-4">
      <div className="d-flex align-items-center mb-3">
        <button
          onClick={() => setCollapsed(c => !c)}
          className={buttonClassName}
        >
          <FontAwesomeIcon
            icon={collapsed ? faChevronRight : faChevronDown}
            fixedWidth
          />
        </button>
        {title}
      </div>
      <div className={`ml-5 ${collapsed ? "d-none" : ""}`}>{children}</div>
    </div>
  )
}

export default CollapsibleContainer
