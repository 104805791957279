import React, { useState } from "react"

import { buildQuery, useQuery } from "micro-graphql-react"
import PropTypes from "prop-types"

import { tutorClient } from "src/graphql-config"

import Pagination from "components/Pagination"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import loadAssignmentsQuery from "./loadAssignmentsQuery"

const TailoredAssignments = props => {
  const { studentId } = props
  const [page, setPage] = useState(1)

  const loadingState = useQuery(
    buildQuery(
      loadAssignmentsQuery,
      { studentId, page },
      { client: tutorClient }
    )
  )

  const spacer = <span style={{ marginLeft: 5, marginRight: 5 }}>|</span>

  return (
    <WithLoadingIndicator
      loadingState={loadingState}
      error="An error occurred while loading assignments"
      heading={<h2>Tailored Assignments</h2>}
    >
      {({ data }) => (
        <React.Fragment>
          <a className="btn solid blue" href={data.student.newAssignmentPath}>
            New Assignment
          </a>
          <ul>
            {data.tailoredAssignmentSummaries.data.map(summary => (
              <li key={summary.tailoredAssignment.id}>
                <span>{summary.tailoredAssignment.name}</span>
                {spacer}
                <a href={summary.resultsPath}>Section Results</a>
                {spacer}
                {summary.aasmState === "initial" && (
                  <React.Fragment>
                    <a href={summary.editAssignmentPath}>Edit Assignment</a>
                    {spacer}
                    <span>Score: Not Started</span>
                  </React.Fragment>
                )}
                {summary.aasmState === "in_progress" && (
                  <span>Score: In Progress</span>
                )}
                {summary.aasmState === "completed" && (
                  <React.Fragment>
                    <a href={summary.generateAssignmentPath}>
                      Generate Assignment
                    </a>
                    {spacer}
                    <span>Score: {(summary.score * 100).toFixed(2)}%</span>
                  </React.Fragment>
                )}
              </li>
            ))}
          </ul>
          <Pagination
            currentPage={page}
            totalPages={data.tailoredAssignmentSummaries.totalPages}
            changePage={page => setPage(page)}
          />
        </React.Fragment>
      )}
    </WithLoadingIndicator>
  )
}

TailoredAssignments.propTypes = {
  studentId: PropTypes.number.isRequired,
}

export default TailoredAssignments
