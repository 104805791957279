import React from "react"

import { ValueFormat } from "~tailwindui/types/enums"

import classNames from "src/classNames"
import formatValue from "src/formatValue"

export type ChipDetailItem = {
  label: string
  Icon: React.FC<any>
  iconClassNames?: string
  stats: { label: string; value: number; valueFormat?: ValueFormat }[]
}

type ChipDetailsProps = {
  details: ChipDetailItem[]
}

const ChipDetails: React.FC<ChipDetailsProps> = ({ details }) => {
  return (
    <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
      {details.map(detail => (
        <li
          key={detail.label}
          className="overflow-hidden rounded-xl border border-gray-200"
        >
          <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
            <detail.Icon
              className={classNames(
                "h-12 w-12 flex-none rounded-lg bg-white object-cover text-white ring-1 ring-gray-900/10",
                "rounded-md p-3",
                detail.iconClassNames
              )}
            />
            <div className="text-sm font-medium leading-6 text-gray-900">
              {detail.label}
            </div>
          </div>

          <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
            {detail.stats.map(stat => (
              <div
                key={stat.label}
                className="flex justify-between gap-x-4 py-3"
              >
                <dt className="text-gray-500">{stat.label}</dt>
                <dd className="text-gray-700">
                  {formatValue(stat.value, stat.valueFormat)}
                </dd>
              </div>
            ))}
          </dl>
        </li>
      ))}
    </ul>
  )
}

export default ChipDetails
