import React, { useEffect, useState } from "react"

import { DefaultModality } from "amazon-chime-sdk-js"

import classNames from "src/classNames"

import CloudroomContext from "components/ChimeCloudroom/CloudroomContext"
import LocalTile from "components/ChimeCloudroom/Shared/LocalTile"
import RemoteTile from "components/ChimeCloudroom/Shared/RemoteTile"
import useChimeCloudroom from "components/ChimeCloudroom/hooks/useChimeCloudroom"
import useCloudroomControls from "components/ChimeCloudroom/hooks/useCloudroomControls"

import Controls from "./Controls"
import IncomingStudentsModal from "./IncomingStudentsModal"
import ScreenShareRequestedModal from "./ScreenShareRequestedModal"

export type TutorLayoutProps = {
  session: any
  localUser: {
    type: "Tutor"
    id: string
  }
  ssoEnabled?: boolean
}

const TutorLayout: React.FC<TutorLayoutProps> = ({ session, localUser }) => {
  const [roster, setRoster] = useState<Record<string, any>>({})
  const cloudroomInfo = useChimeCloudroom({
    session,
    localUser,
    setRoster,
  })
  const controls = useCloudroomControls({
    audioVideo: cloudroomInfo.audioVideo,
    session,
    localUser,
    roster,
  })
  const { reattachVideoElements } = controls

  const remoteAttendeeIds = Object.keys(roster).filter(
    id =>
      new DefaultModality(id).base() !==
      session.chimeJoinInfo.attendee.attendeeId
  )

  const screenSharingAttendeeId = remoteAttendeeIds.find(id => {
    return roster[id].sharingContent
  })

  useEffect(() => {
    reattachVideoElements()
  }, [screenSharingAttendeeId, reattachVideoElements])

  let gridClassNames
  const numAttendees = remoteAttendeeIds.length
  if (numAttendees < 2) gridClassNames = "flex items-center justify-center"
  else if (numAttendees < 3)
    gridClassNames = "sm:grid sm:grid-rows-2 sm:gap-x-4"
  else if (numAttendees < 5)
    gridClassNames = "sm:grid sm:grid-cols-2 sm:gap-x-4"
  else if (numAttendees < 10)
    gridClassNames = "sm:grid sm:grid-cols-3 sm:gap-x-3"
  else if (numAttendees < 16)
    gridClassNames = "sm:grid sm:grid-cols-4 sm:gap-x-2"

  return (
    <CloudroomContext.Provider
      value={{ ...cloudroomInfo, ...controls, roster, localUser }}
    >
      <ScreenShareRequestedModal />
      <IncomingStudentsModal />

      <div className="grid h-screen grid-areas-tutor-slim sm:grid-areas-tutor-wide">
        <aside className="flex flex-col p-2 grid-in-aside sm:max-w-[300px] sm:justify-between">
          <Controls />
          <LocalTile />
        </aside>
        {screenSharingAttendeeId ? (
          <main className="flex">
            <div className="mr-2 flex h-screen">
              <RemoteTile
                attendeeId={screenSharingAttendeeId}
                tutorView={true}
              />
            </div>
            <div className="flex max-h-screen max-w-[500px] flex-wrap overflow-scroll">
              {remoteAttendeeIds.map(id =>
                id === screenSharingAttendeeId ? null : (
                  <RemoteTile
                    attendeeId={id}
                    key={id}
                    tutorView={true}
                    numAttendees={numAttendees}
                  />
                )
              )}
            </div>
          </main>
        ) : (
          <main
            className={classNames(
              "h-screen max-h-screen overflow-scroll",
              gridClassNames
            )}
          >
            {remoteAttendeeIds.map(id => (
              <RemoteTile
                attendeeId={id}
                key={id}
                tutorView={true}
                numAttendees={numAttendees}
              />
            ))}
          </main>
        )}
      </div>
    </CloudroomContext.Provider>
  )
}

export default TutorLayout
