import React from "react"

import { gql } from "@urql/core"

import { useQuery } from "hooks/urql"

import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import Form from "./Form"

const EditOrganization = ({ organizationId, ...rest }) => {
  const [result] = useQuery({
    query: organizationQuery,
    variables: { organizationId },
  })

  return (
    <UrqlLoadingIndicator result={result}>
      {({ data }) => <Form organization={data.organization} {...rest} />}
    </UrqlLoadingIndicator>
  )
}

const organizationQuery = gql`
  query ($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      name
      slug
      emailTimeZone
      details
      calendarEmbedUrl
      chimeCloudroomEnabled
      lowBandwidthCloudroom
      disableRealtimeboardInvites
      disableStudentNotifications
      automatedTwilioSessionsEnabled
      sessionRecordingEnabled
      screenshareRecordingEnabled
      studentScreenShareDisabled
      billingEnabled
      paysByCheck
      hourlyRate
      allowLongSessions
      noShowRate
      allowLongNoShows
      perStudentConcurrentCourseLimit
      studentOneOnOneHoursWeeklyLimit
      courseGroupLimit
      minimumGroupSize
      addressLine
      attention
      intakeBlurb: rawIntakeBlurb
      simpleIntakeBlurb: rawSimpleIntakeBlurb
      minimumAvailabilityWindows
      allowedDomains
      intakeParentRequired
      cleverDistrictId
      classlinkDistrictId
      sessionTimeoutMinutes
      reassignStudentsOnMissingTutor
      omitStatementBalance
      slackChannelId
      minTutorConsistencyRate
      participationDigestEnabled
      participationDigestRecipients

      address {
        country
        region
      }

      primaryAdmin {
        id
        email
      }
    }
  }
`

export default EditOrganization
