import React from "react"

import { motion } from "framer-motion"
import { compress } from "micro-graphql-react"

import { css } from "@emotion/core"

import ordered from "src/ordered"

import EditableList, { Item } from "components/EditableList"

import AddTutors from "./AddTutors"
import NotifyTutors from "./NotifyTutors"

const Tutors = ({ courseCluster }) => {
  return (
    <div>
      <h2
        css={css`
          font-size: 20px;
        `}
      >
        Tutors
      </h2>

      <ul className="list-unstyled">
        <EditableList>
          {ordered(courseCluster.tutors, "lastName").map(tutor => (
            <motion.li key={tutor.id} positionTransition>
              <Item
                mutation={removeTutorMutation}
                mutationProps={{
                  courseClusterId: courseCluster.id,
                  tutorId: tutor.id,
                }}
              >
                {tutor.fullName}
              </Item>
            </motion.li>
          ))}
        </EditableList>
      </ul>
      <AddTutors courseCluster={courseCluster} />
      <NotifyTutors courseClusterId={courseCluster.id} />
    </div>
  )
}

const removeTutorMutation = compress`
  mutation($courseClusterId: ID!, $tutorId: ID!) {
    courseClusterRemoveTutor(courseClusterId: $courseClusterId, tutorId: $tutorId) {
      courseCluster {
        tutors {
          id
          fullName
          lastName
        }
      }
    }
  }
`

export default Tutors
