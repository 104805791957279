import React from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"
import { NotificationContainer } from "react-notifications"

import { css } from "@emotion/core"

import CollapsibleContainer from "components/CollapsibleContainer"
import WithLoadingIndicator from "components/WithLoadingIndicator"

import BudgetlessStudyGroups from "./BudgetlessStudyGroups"
import DurationUpdateRequests from "./DurationUpdateRequests"
import NeedsMatchingUsers from "./NeedsMatchingUsers"
import OpenStudyGroupReplacements from "./OpenStudyGroupReplacements"
import PendingTutorObservations from "./PendingTutorObservations"
import PendingTutorSubjects from "./PendingTutorSubjects"
import UnassignedCourseGroups from "./UnassignedCourseGroups"
import UncontactedStudents from "./UncontactedStudents"
import UnobservedTutors from "./UnobservedTutors"

import "react-notifications/lib/notifications.css"

const Heading = ({ children }) => (
  <h1
    css={css`
      margin: 0;
      font-size: 1.75rem;
      line-height: 1.5rem;
    `}
  >
    {children}
  </h1>
)

const AdminDashboard = () => {
  const loadingState = useQuery(buildQuery(adminQuery))

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <>
          <NotificationContainer />
          {data.viewer.canAdminTutorApplications && (
            <>
              <CollapsibleContainer
                title={<Heading>Pending Tutor Subjects</Heading>}
              >
                <PendingTutorSubjects />
              </CollapsibleContainer>
              <hr />
            </>
          )}
          {data.viewer.canAdminSessions && (
            <>
              <CollapsibleContainer
                title={<Heading>Duration Update Requests</Heading>}
              >
                <DurationUpdateRequests />
              </CollapsibleContainer>
              <hr />
              <CollapsibleContainer
                title={<Heading>Open Study Group Tutor Replacements</Heading>}
              >
                <OpenStudyGroupReplacements />
              </CollapsibleContainer>
              <hr />
            </>
          )}
          {data.viewer.canAdminUsers && (
            <>
              <CollapsibleContainer
                title={<Heading>Uncontacted Students</Heading>}
              >
                <UncontactedStudents />
              </CollapsibleContainer>
              <hr />
            </>
          )}
          {data.viewer.canAdminUserNeedsMatching && (
            <>
              <CollapsibleContainer
                title={
                  <Heading>Needs Matching - Recently added CC/Package</Heading>
                }
              >
                <NeedsMatchingUsers />
              </CollapsibleContainer>
              <hr />
            </>
          )}
          {data.viewer.canAdminUsers && (
            <>
              <CollapsibleContainer
                title={<Heading>Unobserved Tutors</Heading>}
              >
                <UnobservedTutors />
              </CollapsibleContainer>
              <hr />

              <CollapsibleContainer
                title={<Heading>Your Pending Tutor Observations</Heading>}
              >
                <PendingTutorObservations />
              </CollapsibleContainer>
              <hr />
            </>
          )}
          {data.viewer.canAdminOrganizations && (
            <>
              <CollapsibleContainer
                title={<Heading>Unassigned Course Groups</Heading>}
              >
                <UnassignedCourseGroups />
              </CollapsibleContainer>
              <hr />
            </>
          )}
          {data.viewer.canAdminBudgets && (
            <>
              <CollapsibleContainer
                title={<Heading>Study Groups Needing Budgets</Heading>}
              >
                <BudgetlessStudyGroups />
              </CollapsibleContainer>
            </>
          )}
        </>
      )}
    </WithLoadingIndicator>
  )
}

const adminQuery = compress`
  query {
    viewer {
      canAdminUsers
      canAdminUserNeedsMatching
      canAdminSessions
      canAdminOrganizations
      canAdminTutorApplications
      canAdminBudgets
    }
  }
`

export default AdminDashboard
